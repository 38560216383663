import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Illuminaris is a winter holiday celebrated by the people of `}<a parentName="p" {...{
        "href": "/Aljiuedum",
        "title": "Aljiuedum"
      }}>{`Aljiuedum`}</a>{` in which lights are strung up everywhere and lit at night in order to celebrate `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`. Illuminaris is a beloved winter festival in the city of Meripol, known for its stunning displays of lights and its festive atmosphere. The festival takes place over the course of a week in the heart of the city, and it attracts visitors from all over the nation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      